import { useActiveSubscription } from "@/features/subscription/hooks/useActiveSubscription";
import { usePremiumStatus } from "@/features/subscription/hooks/usePremiumStatus";
import { caremeTheme } from "@/libs";
import { auth } from "@/libs/firebase";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Box,
	Container,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Toolbar,
} from "@mui/material";
import { signOut } from "firebase/auth";
import Image from "next/image";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

export function HomeAppBar() {
	const [open, setOpen] = useState(false);
	const router = useRouter();

	const [user, loading, error] = useAuthState(auth);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (user?.uid && !window.fcWidget?.isInitialized()) {
			window.fcWidget?.init({
				token: "f7f28cf8-a1f1-4744-93b7-90c4b02c665d",
				host: "https://healthandrights.freshchat.com",
				tags: ["contact"],
				externalId: user.uid,
				config: {
					headerProperty: {
						hideChatButton: true,
					},
				},
			});
		}

		return () => {
			window.fcWidget?.destroy();
		};
	}, [loading, user?.uid]);

	const toggleDrawer = useCallback(() => {
		setOpen(!open);
	}, [open]);

	const handleLogout = async () => {
		await signOut(auth);
	};

	const { activeSubscription } = useActiveSubscription(auth.currentUser?.uid);
	const { isPremium, platform } = usePremiumStatus(auth.currentUser?.uid);

	return (
		<>
			<Container maxWidth="xs" style={{ padding: 0 }}>
				<AppBar position="sticky" color="inherit" sx={{ boxShadow: "none" }}>
					<Toolbar
						sx={{
							paddingRight: caremeTheme.spacing(3),
							paddingLeft: caremeTheme.spacing(3),
						}}
					>
						<Box py={2} display="flex" sx={{ flexGrow: 1 }}>
							<Image
								src="/logo-green.png"
								onClick={() => router.push("/home")}
								width={130}
								height={27}
								alt="ケアミーのロゴ"
							/>
						</Box>
						<IconButton
							size="large"
							edge="start"
							aria-label="menu"
							onClick={toggleDrawer}
							sx={{ mr: -2 }}
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
			</Container>

			<Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
				<List component="nav">
					{isPremium && platform === "stripe" ? null : (
						<>
							<ListItemButton
								onClick={() => {
									setOpen(false);
									router.push("/premium/purchase");
								}}
							>
								<ListItemText primary="ケアミープレミアムの購入" />
							</ListItemButton>
						</>
					)}

					<ListItemButton
						onClick={() => {
							setOpen(false);
							if (window.fcWidget?.isOpen() !== true) {
								window.fcWidget?.open();
							}
						}}
					>
						<ListItemText primary="お問い合わせ" />
					</ListItemButton>

					<ListItemButton
						onClick={() => {
							setOpen(false);
							router.push("/specified-commercial-transaction-law");
						}}
					>
						<ListItemText primary="特定商取引法に基づく表記" />
					</ListItemButton>
					<ListItemButton
						onClick={() => {
							setOpen(false);
							router.push("/terms");
						}}
					>
						<ListItemText primary="利用規約" />
					</ListItemButton>
					<ListItemButton
						onClick={() => {
							setOpen(false);
							router.push("/privacy-policy");
						}}
					>
						<ListItemText primary="プライバシーポリシー" />
					</ListItemButton>

					{activeSubscription && !activeSubscription.cancelAtPeriodEnd ? (
						<ListItemButton
							onClick={() => {
								setOpen(false);
								router.push("/premium/cancel");
							}}
						>
							<ListItemText primary="ケアミープレミアムの解約" />
						</ListItemButton>
					) : null}

					{user ? (
						<ListItemButton>
							<ListItemText primary="ログアウト" onClick={handleLogout} />
						</ListItemButton>
					) : null}
				</List>
			</Drawer>
		</>
	);
}
