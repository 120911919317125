import { BaseAppBar } from "@/components/Appbar";
import Footer from "@/components/Footer/Footer";

export default function Layout({
	children,
	hideHeader,
	hideFooter,
}: {
	children: React.ReactNode;
	hideHeader?: boolean;
	hideFooter?: boolean;
}) {
	return (
		<>
			{hideHeader ? null : <BaseAppBar />}
			{children}
			{hideFooter ? null : <Footer />}
		</>
	);
}
