import type { Subscription } from "@/features/subscription/types";
import { db } from "@/libs/firebase";
import {
	type FirestoreDataConverter,
	type QueryDocumentSnapshot,
	collection,
	limit,
	query,
	where,
} from "firebase/firestore";
import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

const converter: FirestoreDataConverter<Subscription> = {
	toFirestore(data) {
		return { id: data.id };
	},
	fromFirestore(snapshot: QueryDocumentSnapshot) {
		return {
			id: snapshot.id,
			currentPeriodEnd: snapshot.get("current_period_end").toDate(),
			cancelAtPeriodEnd: snapshot.get("cancel_at_period_end"),
		};
	},
};

export function useActiveSubscription(userId: string | undefined) {
	const subscriptionCollection = collection(
		db,
		`stripeCustomers/${userId}/subscriptions`,
	).withConverter(converter);
	const subscriptionQuery = query(
		subscriptionCollection,
		where("status", "in", ["active", "trialing"]),
		limit(1),
	);

	const [values] = useCollectionData(subscriptionQuery);

	const activeSubscription = values?.[0];

	return useMemo(
		() => ({
			activeSubscription,
		}),
		[activeSubscription],
	);
}
