import { Colors, ThemeColors } from "@/config/colors";
import { createTheme } from "@mui/material";

declare module "@mui/material/Button" {
	interface ButtonPropsVariantOverrides {
		primaryLight: true;
	}
}

// Care.me スタイルガイド
// https://www.figma.com/file/Gls6W9Se5UZPFDeae0aD3M/202111_Style-Guide
export const caremeTheme = createTheme({
	typography: {
		allVariants: {
			color: Colors.grey[500],
			fontFamily: [
				"Noto Sans JP",
				"Helvetica Neue",
				"Arial",
				"Hiragino Kaku Gothic ProN",
				"Hiragino Sans",
				"Meiryo",
				"sans-serif",
			].join(", "),
		},
		h1: {
			fontSize: 96,
			fontWeight: 700,
			lineHeight: 1.25,
			letterSpacing: -(96 * 0.02),
		},
		h2: {
			fontSize: 64,
			fontWeight: 700,
			lineHeight: 1.25,
			letterSpacing: -(64 * 0.02),
		},
		h3: {
			fontSize: 48,
			fontWeight: 700,
			lineHeight: 1.25,
			letterSpacing: -(48 * 0.02),
		},
		h4: {
			fontSize: 32,
			fontWeight: 700,
			lineHeight: 1.25,
			letterSpacing: -(32 * 0.02),
		},
		h5: {
			fontSize: 24,
			fontWeight: 700,
			lineHeight: 1.25,
			letterSpacing: -(24 * 0.02),
		},
		h6: {
			fontSize: 18,
			fontWeight: 700,
			lineHeight: 1.4,
			letterSpacing: -(18 * 0.02),
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 700,
			lineHeight: 1.4,
			letterSpacing: 0,
		},
		subtitle2: {
			fontSize: 14,
			fontWeight: 700,
			lineHeight: 1.55,
			letterSpacing: 0,
		},
		body1: {
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 1.6,
			letterSpacing: 0,
		},
		body2: {
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.6,
			letterSpacing: 0,
		},
		button: {
			fontSize: 14,
			fontWeight: 700,
			lineHeight: 1.2,
			letterSpacing: 0,
			color: "white",
		},
		caption: {
			fontSize: 12,
			fontWeight: 400,
			lineHeight: 1.55,
			letterSpacing: 0,
		},
		overline: {
			fontSize: 10,
			fontWeight: 400,
			lineHeight: 1.55,
			letterSpacing: 0,
		},
	},
	palette: {
		primary: {
			light: ThemeColors[0].colors.primary[400],
			main: ThemeColors[0].colors.primary[500],
			dark: ThemeColors[0].colors.primary[600],
			contrastText: "white",
		},
		secondary: {
			light: ThemeColors[0].colors.secondary[400],
			main: ThemeColors[0].colors.secondary[500],
			contrastText: "white",
		},
		grey: {
			...Colors.grey,
		},
		text: {
			primary: Colors.grey[500],
		},
	},
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				sizeSmall: {
					borderRadius: 50,
				},
				sizeMedium: {
					height: 48,
					borderRadius: 50,
				},
				sizeLarge: {
					borderRadius: 50,
				},
			},
			variants: [
				{
					props: { variant: "primaryLight" },
					style: {
						backgroundColor: ThemeColors[0].colors.primary[400],
						color: Colors.grey[500],
						"&:hover": {
							backgroundColor: `${ThemeColors[0].colors.primary[400]}80`,
						},
					},
				},
			],
		},
		MuiTextField: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === "standard" && {
						input: {
							fontSize: "14px",
							paddingBottom: theme.spacing(1.5),
						},
					}),
				}),
			},
		},
	},
});
