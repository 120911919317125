import { ErrorBoundary } from "@/components/ErrorBoundary/ErrorBoundary";
import Layout from "@/components/Layout/Layout";
import { caremeTheme } from "@/libs/theme";
import "@/styles/globals.css";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/nextjs";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { SnackbarProvider } from "notistack";
import type { ReactElement, ReactNode } from "react";

Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	tracesSampleRate: 1.0,
	environment: process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV,
	enabled: process.env.NODE_ENV === "production",
});

// https://nextjs.org/docs/basic-features/layouts#with-typescript
export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
	P,
	IP
> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
	const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

	return (
		<ThemeProvider theme={caremeTheme}>
			<SnackbarProvider>
				<ErrorBoundary>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>
			</SnackbarProvider>
		</ThemeProvider>
	);
}
