import { getSubscriber } from "@/features/subscription/api/getSubscriber";
import type { Subscriber } from "@/features/subscription/types";
import { useEffect, useMemo, useState } from "react";

export const usePremiumStatus = (userId?: string) => {
	const [subscriber, setSubscriber] = useState<Subscriber["subscriber"]>();
	const [expiresDate, setExpiresDate] = useState<Date | null>();
	const [isPremium, setIsPremium] = useState(false);

	useEffect(() => {
		if (!userId) return;
		getSubscriber(userId).then((d) => setSubscriber(d.subscriber));
	}, [userId]);

	useEffect(() => {
		if (!subscriber) return;
		const premium = subscriber.entitlements.premium as { expires_date: string };
		const expiresDate = new Date(premium?.expires_date);
		const isPremium = expiresDate > new Date();
		setIsPremium(isPremium);
		setExpiresDate(
			isPremium && premium ? new Date(premium.expires_date) : null,
		);
	}, [subscriber]);

	const platform = useMemo(() => {
		const productIdentifier =
			subscriber?.entitlements?.premium?.product_identifier;
		if (!productIdentifier) return null;

		const subscription = subscriber.subscriptions[productIdentifier];
		if (!subscription) return null;

		return subscription.store as "stripe" | "play_store" | "app_store";
	}, [
		subscriber?.entitlements?.premium?.product_identifier,
		subscriber?.subscriptions,
	]);

	return useMemo(
		() => ({
			isPremium,
			expiresDate,
			platform,
		}),
		[isPremium, expiresDate, platform],
	);
};
