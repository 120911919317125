// カラーパレット
// https://www.figma.com/file/Gls6W9Se5UZPFDeae0aD3M/202111_Style-Guide?node-id=2%3A7&t=M2nfoLYHmRnGwcZd-0
export const Colors = {
	grey: {
		600: "#011C11",
		500: "#474B4A",
		400: "#B4B6B5",
		300: "#E0E3E2",
		200: "#F6F6F6",
	},
};

export const ThemeColors = [
	{
		name: "ケアミーグリーン",
		colors: {
			primary: {
				600: "#1BC79A",
				500: "#37E4B6",
				400: "#DFFBF3",
			},
			secondary: {
				500: "#FF7075",
				400: "#FFE8E9",
			},
		},
	},
	//...
];
