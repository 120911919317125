import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_UR,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, "asia-northeast1");

// エミュレータへの接続
if (process.env.NODE_ENV !== "production") {
	// @ts-expect-error: Nextのホットリロードのタイミングで、すでに起動しているシュミレーターを起動しようとしてエラーになる。
	// これを回避するために、すでにエミュレーターが起動しているかどうかをチェックしている。
	if (functions.emulatorOrigin !== "http://localhost:5001")
		connectFunctionsEmulator(functions, "localhost", 5001);

	// @ts-expect-error: Nextのホットリロードのタイミングで、すでに起動しているシュミレーターを起動しようとしてエラーになる。
	// これを回避するために、すでにエミュレーターが起動しているかどうかをチェックしている。
	if (db._settings.host !== "localhost:8080")
		connectFirestoreEmulator(db, "localhost", 8080);

	connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });
}
