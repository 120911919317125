import { caremeTheme } from "@/libs";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";

export function BaseAppBar() {
	const router = useRouter();

	return (
		<Container maxWidth="xs" style={{ padding: 0 }}>
			<AppBar position="sticky" color="inherit" sx={{ boxShadow: "none" }}>
				<Toolbar
					sx={{
						paddingRight: caremeTheme.spacing(3),
						paddingLeft: caremeTheme.spacing(3),
					}}
				>
					<Box py={2} display="flex" sx={{ flexGrow: 1 }}>
						<Image
							src="/logo-green.png"
							onClick={() => router.push("/home")}
							width={130}
							height={27}
							alt="ケアミーのロゴ"
						/>
					</Box>
				</Toolbar>
			</AppBar>
		</Container>
	);
}
