import { BaseAppBar } from "@/components/Appbar";
import Footer from "@/components/Footer/Footer";
import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import type { ReactNode } from "react";

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
	return (
		<SentryErrorBoundary
			fallback={
				<>
					<BaseAppBar />
					<Container component="header" maxWidth="xs">
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							flexDirection="column"
							height={300}
						>
							<Typography textAlign="center">
								問題が発生しました。
								<br />
								一度ページをリロードして問題が解決しないかお試しください。
								<br />
								それでも解決しない場合、しばらく時間を置いてから再度アクセスをお試しください。
							</Typography>
						</Box>
					</Container>
					<Footer />
				</>
			}
		>
			{children}
		</SentryErrorBoundary>
	);
};
