
import { auth } from "@/libs";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import { useAuthState } from "react-firebase-hooks/auth";

export default function Footer() {
	const [user] = useAuthState(auth);

	return (
		<Container component="footer" maxWidth="xs" style={{ padding: 0 }}>
			<Box px={3} py={8} bgcolor="rgba(71, 75, 74, 0.05);">
				<Link
					href={user ? "/home" : "/signin-with-email"}
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<Typography variant="body2" mt={2}>
						ログイン
					</Typography>
				</Link>

				<Link
					href={"/premium"}
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<Typography variant="body2" mt={2}>
						ケアミープレミアム
					</Typography>
				</Link>

				<Link
					href={"/specified-commercial-transaction-law"}
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<Typography variant="body2" mt={2}>
						特定商取引法に基づく表記
					</Typography>
				</Link>

				<Link
					href={"/terms"}
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<Typography variant="body2" mt={2}>
						利用規約
					</Typography>
				</Link>

				<Link
					href={"/privacy-policy"}
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<Typography variant="body2" mt={2}>
						プライバシーポリシー
					</Typography>
				</Link>

				<Link
					href={"https://healthandrights.jp/"}
					target="_blank"
					style={{ textDecoration: "none", color: "inherit" }}
				>
					<Typography variant="body2" mt={2}>
						運営会社
					</Typography>
				</Link>

				<Box mt={2}>
					<Link href={"https://twitter.com/care_me_app"} target="_blank">
						<Image
							src="/twitter-icon.png"
							width={35}
							height={35}
							alt="ケアミーのTwitterアカウント"
						/>
					</Link>
				</Box>

				<Box mt={6}>
					<Image
						src="/logo-gray.png"
						width={130}
						height={27}
						alt="ケアミーのロゴ"
					/>
				</Box>

				<Typography variant="body2" mt={2} fontWeight={400}>
					{`©${new Date().getFullYear()} Health & Rights Inc.`}
				</Typography>
			</Box>
		</Container>
	);
}
