import type { Subscriber } from "@/features/subscription/types";

export const getSubscriber = async (userId: string) => {
	const res = await fetch(
		`https://api.revenuecat.com/v1/subscribers/${userId}`,
		{
			headers: {
				"Content-Type": "application/json",
				"X-Platform": "stripe",
				Authorization: `Bearer ${
					// biome-ignore lint/style/noNonNullAssertion: <explanation>
					process.env.NEXT_PUBLIC_REVENUECAT_STRIPE_APP_PUBLIC_API_KEY!
				}`,
			},
		},
	)
		.then((res) => res.json())
		.then((json) => json);

	return res as Subscriber;
};
